import en from './en.json'
import es from './es.json'
import pt from './pt.json'

const messages = {
  en,
  es,
  pt
}

export default messages

<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="49.183" height="49.183" viewBox="0 0 49.183 49.183">
        <path id="_001-facebook" data-name="001-facebook" d="M46.468,0H2.713A2.714,2.714,0,0,0,0,2.715V46.47a2.714,2.714,0,0,0,2.715,2.713H26.273V30.163H19.885V22.718h6.388V17.239c0-6.353,3.879-9.812,9.546-9.812a52.555,52.555,0,0,1,5.727.293v6.64H37.638c-3.084,0-3.681,1.465-3.681,3.616v4.741H41.33l-.961,7.445H33.957v19.02H46.468a2.714,2.714,0,0,0,2.715-2.714h0V2.712A2.714,2.714,0,0,0,46.468,0Zm0,0" transform="translate(0 0)"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#FFFFFF'
        }
    }
}
</script>